import React, { Component } from 'react'
import '../App.css'

export default
class Footer extends Component {
    render () {
        return (
            <div className='footer'>
                Copyright © Nightwind 2022
            </div>
        )
    }
}